import React from 'react'
import { useState } from 'react';
import "../styles/AdminLogin.css"

export default function AdminLogin(props) {
    // State for form fields and error messages
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
 
    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform client-side validation
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            // If no validation errors, you can send the data to your server for authentication
            // Example: Send a POST request to your server with formData
            console.log('Form data:', formData);

            if ((formData.email=="musa@musafx.com")&&(formData.password=="musa112233")){
                alert("Welcome to the Admin Page")
               props.setLogin(true)
            }
            else{
                alert("invalid Login")
            }

            // Clear form fields
            setFormData({
                email: '',
                password: '',
            });
        } else {
            setErrors(validationErrors);
        }
    };

    // Client-side validation function
    const validateForm = (data) => {
        let errors = {};

        // Validate email
        if (!data.email) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email format';
        }

        // Validate password (you can add more rules here)
        if (!data.password) {
            errors.password = 'Password is required';
        }

        return errors;
    };

    // Helper function to validate email format
    const isValidEmail = (email) => {
        // Simple email validation regex
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };


    return (
        <div className='admin-login-container'>
            <h2>Login</h2>
            <div className='form-container'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <div className="error">{errors.email}</div>}
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        {errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    )
}
