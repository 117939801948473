import React from 'react'
import "../styles/AutoPilotpro.css"
import bkg1 from "../images/fximage.webp"
import bkg2 from "../images/fximage.png"
import TradingViewWidget from '../components/TradingViewWidget'
import Footer from "../components/Footer";
import { useDispatch, useSelector } from 'react-redux'
import { addproduct } from "../redux/generalCart"
import { Link } from 'react-router-dom';

export default function () {
    class Product {
        constructor(name, quantity, price, image) {
            this.name = name || ""
            this.quantity = quantity || 0
            this.price = price || 0
            this.image = image || ""
        }
    }


    let autopilotpro = new Product();
    autopilotpro.name = "AutoPilot Pro PLUS+(version w19)";
    autopilotpro.price = 180;
    autopilotpro.quantity = 1;
    autopilotpro.image = "../images/fximage.png";

    const generalCart = useSelector((state) => state.generalCart.generalCart);
    const dispatch = useDispatch();

    return (
        <div className='autopilotpro'>
            <div className='L1'>
                <div className='L1-left'>
                    <img src={bkg1} alt='bkg' />
                </div>
                <div className='L1-right'>
                    <h3>ON SALE</h3>
                    <h2>AutoPilot Pro PLUS+ (version w19)</h2>
                    <p>R10,000</p>
                    <h2>R3,000</h2>
                    <button onClick={() => {
                        dispatch(
                            addproduct(autopilotpro)
                        );
                    }} >Add to Cart</button>
                    <button>
                        <Link to="/checkout">Buy it now</Link>
                    </button>

                </div>

            </div>
            <div className='L2'>
                <h1>WHAT IS AUTOPILOT PRO PLUS?</h1>
                <p>The Autopilot pro is a trading robots/software that were designed to help unprofitable traders become profitable. 99% of traders fail trying to trade manually, automating your trading by using trading robots/softwares is the way to go! It’s suitable for beginners experienced Traders and professional traders alike</p>

                <div className='description'>
                    <p>Its 100% fully automated</p>

                    <p>comes with MENTORSHIP</p>

                    <p>works on phone & laptop</p>

                    <p>doesn't require trading experience</p>

                    <p>installation videos and guides included</p>

                    <p>works with any broker</p>

                    <p>trades 24/7</p>

                    <p>works on MT4 & MT5</p>

                    <p>minimum deposit R950/$50</p>

                    <p>24/7 support included</p>
                </div>

            </div>
            <div className='L3'>
                <h1>TRADE LIKE A PRO+</h1>
            </div>
            <div className='L4'>
                <button className='siteButton' onClick={() => {
                    dispatch(
                        addproduct(autopilotpro)
                    );
                }} > <Link to="/checkout">Order Now</Link></button>

                <h1>GET AUTOMATED TODAY</h1>
                <p>STOP BLOWING ACCOUNTS and get the AUTOPILOT PRO+ while its still one SALE !!!</p>

            </div>

            <div className='L5'>
                <TradingViewWidget />
            </div>


        </div>
    )
}
