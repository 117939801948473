import React from 'react'

import '../styles/Refundpolicy.css'

const Refundpolicy = () => {
    return (
        <div className="refundpolicy">
            <div className="refundpolicy-container">
               
                    <h1>Refund Policy</h1>
                
                
                
                <div className='refundpolicy-body'>
                    <p> There are no refunds for any products/services offered by MusaFX. All sales are final.</p>
                    
                    <p>Refunds or account credits will not be issued in situations as follows</p>
                    
                    <ul>
                        <li>You have changed your mind about using our product and service</li>
                        
                        <li>You purchased our product/services by mistake</li>
                        
                        <li>You do not have sufficient expertise to use our system</li>
                        
                        <li>You experience dissatisfaction with our system after purchase</li>
                    </ul>

                </div>


            </div>


        </div>
    )
}

export default Refundpolicy
