import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../styles/Navbar.css';

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const navMenu = useRef(null);
  const generalCart = useSelector((state) => state.generalCart.generalCart);

  const toggleMenu = () => {
    setShowNav(!showNav);
    if (!showNav) {
      navMenu.current.classList.add('nav-links-mobile');
    } else { 
      navMenu.current.classList.remove('nav-links-mobile');
    }
  };

  const closeMenu = () => {
    setShowNav(false);
    navMenu.current.classList.remove('nav-links-mobile');
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <NavLink to="/" onClick={closeMenu}>
          <div className="logo">musa<span>fx</span></div>
        </NavLink>

        <ul className="nav-links" ref={navMenu}>
          <li>
            <NavLink to="/autopilot" onClick={closeMenu}>
              AutoPilotpro
            </NavLink>
          </li>
          <li>
            <a href="https://t.me/MUSAFX24" onClick={closeMenu}>
              Telegram
            </a>
          </li>
          <li>
            <NavLink to="/support" onClick={closeMenu}>
              Support
            </NavLink>
          </li>
          <li>
            <NavLink to="/Brokers" onClick={closeMenu}>
              Brokers
            </NavLink>
          </li>
        </ul>
        <div className='other-nav-menu' >
          <Link to="/account" onClick={closeMenu}> <i class="fa-solid fa-user"></i></Link>
          <div onClick={closeMenu}>
            <Link to="/checkout">
              <i class="fa-solid fa-cart-arrow-down"></i>
              ({generalCart == undefined ? "0" : `${generalCart.length}`})
            </Link>

          </div>
        </div>

        <div className="menu-icon" onClick={toggleMenu}>
          {showNav ? (
            <i className="fa-solid fa-circle-xmark"></i>
          ) : (
            <i className="fa-solid fa-bars"></i>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
