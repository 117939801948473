import React from 'react'

import Footer from './Footer';
import '../styles/Privacypolicy.css';


const Privacypolicy = () => {
    return (
        <div className='privacypolicy'>
            <div className='privacypolicy-container'>

                <h1> Privacy Policy</h1>
                <div className="privacypolicy-body">
                    <p>This Privacy Policy describes how MusaFX.com
                        (the “Site” or “we”) collects, uses, and discloses your Personal
                        Information when you visit or make a purchase from the Site.
                    </p>
                    <h1>Contact</h1>
                    <p>After reviewing this policy, if you have
                        additional questions, want more information about our privacy practices,
                        or would like to make a complaint, please contact us by e-mail at info@musafx.com
                        or by mail using the details provided below: MusaFX, No. 3 Dr. Swanner Pool Road, Montana,
                        Johannesburg, Gauteng, 0812, South Africa.
                    </p>
                    <h1>Collecting Personal Information</h1>
                    <p>When you visit the Site, we collect certain information about your device,
                        your interaction with the Site, and information necessary to process your purchases.
                        We may also collect additional information if you contact us for customer support.
                        In this Privacy Policy, we refer to any information about an identifiable individual (including the information below) as “Personal Information”.
                        See the list below for more information about what Personal Information we collect and why.
                    </p>
                    <ul>
                        <li>
                            <u>Device information</u>
                        </li>
                        <ul>
                            <li>
                                Purpose of collection: to load the Site accurately for you,
                                and to perform analytics on Site usage to optimize our Site.
                            </li>
                            <li>
                                Source of collection: Collected automatically when you access our Site using cookies, log files,
                                web beacons, tags, or pixels [ADD OR SUBTRACT ANY OTHER TRACKING TECHNOLOGIES USED].
                            </li>
                            <li>
                                Disclosure for a business purpose: shared with our processor Shopify
                                [ADD ANY OTHER VENDORS WITH WHOM YOU SHARE THIS INFORMATION].
                            </li>
                            <li>
                                Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with
                                the Site [ADD OR SUBTRACT ANY OTHER PERSONAL INFORMATION COLLECTED].
                            </li>
                        </ul>
                        <li>
                            <u>Order information</u>
                        </li>
                        <ul>
                            <li>
                                Purpose of collection: to provide products or services to you to fulfill our contract,
                                to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us,
                                provide you with information or advertising relating to our products or services.
                            </li>
                            <li>
                                Source of collection: collected from you.
                            </li>
                            <li>
                                Disclosure for a business purpose: shared with our processor Shopify [ADD ANY OTHER VENDORS WITH WHOM YOU SHARE THIS INFORMATION. FOR EXAMPLE, SALES CHANNELS, PAYMENT GATEWAYS, SHIPPING AND FULFILLMENT APPS].
                            </li>
                            <li>
                                Personal Information collected: name, billing address, shipping address, payment information (including credit card numbers [INSERT ANY OTHER PAYMENT TYPES ACCEPTED]), email address, and phone number.
                            </li>
                        </ul>
                        <li>
                            <u>Customer support information</u>
                        </li>
                        <ul>
                            <li>
                                Purpose of collection: to provide customer support.
                            </li>
                            <li>
                                Source of collection: collected from you.
                            </li>
                            <li>
                                Disclosure for a business purpose: [ADD ANY VENDORS USED TO PROVIDE CUSTOMER SUPPORT]
                            </li>
                            <li>
                                Personal Information collected: [INSERT ANY OTHER INFORMATION YOU COLLECT: OFFLINE DATA, PURCHASED MARKETING DATA/LISTS]
                            </li>
                            <li>
                                Purpose of collection: to provide customer support.
                            </li>
                            <li>
                                Source of collection: collected from you.
                            </li>
                            <li>
                                Disclosure for a business purpose: [ADD ANY VENDORS USED TO PROVIDE CUSTOMER SUPPORT]
                            </li>
                            <li>
                                Personal Information collected: [ADD ANY MODIFICATIONS TO THE INFORMATION LISTED ABOVE OR ADDITIONAL INFORMATION AS NEED.]
                            </li>
                        </ul>
                    </ul>
                    <h1>Minors</h1>
                    <p>The Site is not intended for individuals under the age of [INSERT AGE]. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address above to request deletion.</p>
                    <h1>Sharing Personal Information</h1>
                    <p>We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:</p>
                    <ul>
                        <li>
                            We use Shopify to power our online store. You can read more about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy.
                        </li>
                        <li>
                            We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant, or other lawful request for information we receive, or to otherwise protect our rights.
                        </li>
                    </ul>
                    <h1>Behavioural Advertising</h1>
                    <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For example:</p>
                    <ul>
                        <li>
                            We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
                        </li>
                        <li>
                            We share information about your use of the Site, your purchases, and your interaction with our ads on other websites with our advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases through the use of cookies or other similar technologies (which you may consent to, depending on your location).
                        </li>
                        <li>
                            We use Shopify Audiences to help us show ads on other websites with our advertising partners to buyers who made purchases with other Shopify merchants and who may also be interested in what we have to offer. We also share information about your use of the Site, your purchases, and the email address associated with your purchases with Shopify Audiences, through which other Shopify merchants may make offers you may be interested in.
                        </li>
                    </ul>
                    <p>For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
                    <p>You can opt out of targeted advertising by:</p>
                    <p>INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED. COMMON LINKS INCLUDE:</p>
                    <ul>
                        <li>
                            <i>FACEBOOK - https://www.facebook.com/settings/?tab=ads </i>
                        </li>
                        <li>
                            <i>GOOGLE - https://www.google.com/settings/ads/anonymous </i>
                        </li>
                        <li>
                            <i>BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</i>
                        </li>
                        <p>
                            <i>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: https://optout.aboutads.info/.</i>
                        </p>
                    </ul>
                </div>



            </div>

        </div>
    )
}

export default Privacypolicy
