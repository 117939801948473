import React from 'react'
import { useSelector } from 'react-redux'
import dataFetch from "./modules/dataFetch";
import { useDispatch } from 'react-redux';
import { login,logout } from '../redux/user';
import { generallogin, generallogout } from '../redux/generalLoginCheck';
import { baseURL } from '../utils';



function LogComponent() {
 
  const user = useSelector((state)=>state.user.value);
  const generalLoginCheck = useSelector((state) => state.generalLoginCheck.logedIn);
 const dispatch = useDispatch();


function loggerin(){

  
    // console.log('Login data:', formData);
    // You can perform further actions here, such as sending the data to a server
    let fData = new FormData();
    fData.append("email", "me.chimaobi@gmail.com");
    fData.append("password", "chimsyboy");
    fData.append("testlink", "yes");

    
    const url = baseURL;
    dataFetch(url, fData)
      .then(data => {
          console.log(data)
      })
      .catch(error => {
        console.error(error);
      });


}


  
  return (
    <div>
      <h1>Profile</h1>
      <h2>User Loggedin?: {generalLoginCheck}</h2>
        <br/>
     
     
    
   


      
      <button onClick={()=>{
              loggerin ()
      }}>Loggin</button>
      

      
      
    </div>
  )
}

export default LogComponent 