import React from 'react'
import "../styles/TradingComponents.css"
import twitterlogo from "../images/twitterlogo.png"

function TradingComponents(props) {
    return (
        <div className='comp'>
            <div className='comp-left'>
                <div className='title-cont'>
                    <h2>{props.title}</h2>
                    <img src={twitterlogo} alt='' />
                </div>
                <div>
                    <p>{props.text}</p>
                </div>
            </div>
            <div className='comp-right'>
                <img src={props.image} alt='' />
            </div>
        </div>
    )
}

export default TradingComponents