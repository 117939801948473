import React, { useState } from 'react'
import AdminDashboard from '../components/AdminDashboard'
import AdminLogin from '../components/AdminLogin'
function Admin() {
   const [loginSucess, setLoginSucess]= useState(false);
  return (
    <div style={{background:"white"}}>
      
       {loginSucess?(<AdminDashboard setLogin={setLoginSucess} />):
       <AdminLogin setLogin={setLoginSucess}/>
       }
        
         
    </div>
  )
}

export default Admin