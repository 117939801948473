import React, { useState, useCallback } from "react";
import "../styles/DashboardWithdrawal.css";
import { useSelector } from "react-redux";
import { baseURL } from '../utils';
import dataFetch from "./modules/dataFetch";

export default function DashboardWithdrawal() {
  const user = useSelector((state) => state.user.value);
  const [imfForm, showImfForm] = useState(true);
  const [showCurrencyPercentageMessage, setShowCurrencyPercentageMessage] = useState(true);
  const [imfCode, setImfCode] = useState("");
  const [aMLCode, setAMLCode] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [imfCodeError, setImfCodeError] = useState("");
  const [aMLCodeError, setAMLCodeError] = useState("");
  const [taxCodeError, setTaxCodeError] = useState("");
  

  const handleSubmit2 = useCallback(() => {
    if (imfCode.length !== 8) {
      setImfCodeError("Imf code should be 8 characters");
    } else if (aMLCode.length !== 6) {
      setAMLCodeError("AML code should be 6 characters");
      setImfCodeError("");
    } else if (taxCode.length !== 4) {
      setTaxCodeError("Tax code should be 4 characters");
      setImfCodeError("");
      setAMLCodeError("");
    } else {
      setImfCodeError("");
      setAMLCodeError("");
      setTaxCodeError("");
      if (aMLCode === "770077") {
        setShowCurrencyPercentageMessage(true);
      } else {
        alert("Invalid AML code. Please request your unique AML code.");
      }
    }
  }, [imfCode, aMLCode, taxCode]);

  const [formData, setFormData] = useState({
    bankname: "",
    accountnumber: "",
    amount: "",
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showWithdrawalConfirmationMessage, setShowWithdrawalConfirmation] = useState(false);

  const validateForm = useCallback(() => {
    const newErrors = {};
    let valid = true;

    if (!formData.bankname) {
      newErrors.bankname = "Bank Name is required";
      valid = false;
    }

    if (!formData.accountnumber) {
      newErrors.accountnumber = "Account Number is required";
      valid = false;
    } else if (formData.accountnumber.length < 5) {
      newErrors.accountnumber = "Enter a valid Account Number";
      valid = false;
    }

    if (!formData.amount || formData.amount < 50) {
      newErrors.amount = "Amount is required";
      valid = false;
    } else if (!/^\d+(\.\d{1,2})?$/.test(formData.amount)) {
      newErrors.amount = "Invalid Amount format";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }, [formData]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      alert("Account Details Set");
      showImfForm(true);
    }
  }, [validateForm]);

  return (
    <div className="withdrawals">
      {user.investmentEnabled === "TRUE" ? (
        <div>
          {!showWithdrawalConfirmationMessage ? (
            <div>
              <form>
                <div>
                  <label htmlFor="bankname">Bank Name</label>
                  <input
                    type="text"
                    id="bankname"
                    name="bankname"
                    value={formData.bankname}
                    onChange={handleInputChange}
                  />
                  {errors.bankname && <p className="error">{errors.bankname}</p>}
                </div>
                <div>
                  <label htmlFor="accountnumber">Account Number</label>
                  <input
                    type="number"
                    id="accountnumber"
                    name="accountnumber"
                    value={formData.accountnumber}
                    onChange={handleInputChange}
                  />
                  {errors.accountnumber && <p className="error">{errors.accountnumber}</p>}
                </div>
                {/* <div>
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                  />
                  {errors.amount && <p className="error">{errors.amount}</p>}
                </div> */}
                <button
                  type="button"
                 
                  onClick={ async() => {

                    if (!formData.bankname) {
                        alert( "Bank Name is required");
                       return
                      }
                      else if (!formData.accountnumber){
                        alert( "Account number is required");
                        return
                      }
                      else{
                        // CONTINUE FROM HERE
                      console.log(user)
                    let fData = new FormData();
                    fData.append("sendwithdrawalemail", formData.email);
                    fData.append("email", user.email);
                    fData.append("fname", user.firstName);
                    fData.append("amount", user.withdrawalableAmount);
              
                 
                    const url = baseURL;
                    dataFetch(url, fData)
                      .then(data => {
                        console.log(data); 
                        setShowWithdrawalConfirmation(true);

                    })
                        
                        .catch(e=> console.log(e))
                      }
                     
                      
                    
                    

                  }}
                >
                Proceed
                </button>
              </form>
            </div>
          ) : (
            <div className='percentagemessage'>
               <p >
                                            Hi {user.firstName},<br /> <br />

                                            Withdrawal In Progress!!<br /> <br />

                                            Withdrawal of your Musa FX total account balance has been initiated and is in Progress, be on the look out for a confirmation email. <br></br>
                                            <br></br>
                                            Ensure you don't place multiple withdrawals as this could affect the process.<br></br>


                                           
                                            <br></br>
                                            Thank you for choosing Musa FX and entrusting us with your funds.<br /><br />
                                        </p>
            </div>
          )}
        </div>
      ) : (
        <div className="no-investments">
          <i className="fa-regular fa-hourglass icon"></i>
          <h1>You have not made any investments</h1>
        </div>
      )}
    </div>
  );
}
