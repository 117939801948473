import { BrowserRouter as Router, Route, Switch } from "react-router-dom/cjs/react-router-dom"
// =======================ROUTES============================================
import Home from "../src/routes/Home"
import Support from "../src/routes/Support"
import Navbar from "./components/Navbar";
import Brokers from "./routes/Brokers";
import AutoPilotpro from "./routes/AutoPilotpro";
import Checkout from "./routes/Checkout";
import AccountPage from "./routes/AccountPage";
import Admin from "./routes/Admin";
//========================= END OG ROUTES========================================
//========================== OTHER COMPONENTS======================================
import Footer from "./components/Footer";
import DashBoard from "./routes/DashBoard";
import TOS from "../src/components/TOS"
import Privacypolicy from "../src/components/Privacypolicy"
import Refundpolicy from "../src/components/Refundpolicy"
import PlansPage from "./routes/PlansPage";

import LogComponent from "./components/LogComponent";

import GoogleTranslate from "./components/GoogleTranslate"
//==========================END OF OTHER COMPONENTS===============================




//=============================STATE VARIABLES================================
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'



function App() {

  const generalLoginCheck = useSelector((state) => state.generalLoginCheck.logedIn);
  const user = useSelector((state) => state.user.value);

  return (
    // <LogComponent/>
    <Router>
      {(generalLoginCheck && user.firstName != "") ? (<DashBoard />) :

        (<div>
          <Navbar />
          <Switch>

            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route path="/brokers">
              <Brokers />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>
            <Route path="/autopilot">
              <AutoPilotpro />
            </Route>
            <Route path="/account">
              <AccountPage />
            </Route>
            <Route path="/terms-of-service">
              <TOS />
            </Route>
            <Route path="/privacy">
              <Privacypolicy />
            </Route>
            <Route path="/refund">
              <Refundpolicy />
            </Route>
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path="/investments">
            <PlansPage/>
            </Route>

          </Switch>
          <Footer/>
        </div>)}






    </Router>
  );
}

export default App;
