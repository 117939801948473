

import React, { useState } from 'react';
import "../styles/Footer.css"
import { NavLink } from 'react-router-dom';


function Footer() {


    // ----------------------------------------------NEWSLETTER FORM START
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    };

    const validateEmail = () => {
        if (!email.match(/^\S+@\S+\.\S+$/)) {
            setEmailError('Invalid email format');
            return false;
        }
        setEmailError('');
        return true;
    };

    const handleResetPassword = (event) => {
        event.preventDefault();

        if (validateEmail()) {
            alert("Email added")
        }
    };
    //...............................NEWS LETTER FORM ENDING  


    return (
        <div className='Footer'>
            <div className='footer-container'>
                <div className='layer1'>
                    <div>
                        <h3>Links</h3>
                        <ul className='footer-links'>
                            <li><NavLink to="/privacy">Privacy policy</NavLink></li>
                            <li><NavLink to="/refund">Refund Policy</NavLink></li>
                            <li><NavLink to="/terms-of-service">Terms and conditions</NavLink></li>
                            <li><NavLink to="/support">Support</NavLink></li>

                        </ul>

                    </div>



                    <div className='layer1-right'>
                        <h3>News Letter</h3>

                        <form onSubmit={handleResetPassword} className='newsletterform'>
                            <div>

                                <input
                                    type="email"
                                    id="email"
                                    placeholder="email@example.com"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <span className="error">{emailError}</span>
                            </div>
                            <button className='siteButton newsletterbutton' type="submit">Subscribe</button>
                        </form>
                        
                    </div>



                </div>
                <div className='layer2'>
                    <p><span>Phone number:</span> +27 677974351</p>
                    <p> <span>Email:</span> info@musafx.com</p>
                </div>
                <div className='layer3'>
                    <p className='risk-warning'>Risk Warning: Trading leveraged products such as Forex may not be suitable for all investors as they carry a degree of risk to your capital. You must be aware of the risks and be willing to bear any level of risk to invest in financial markets. Past performance is not indicative of future results. musafx and all individuals associated assume no responsibility for your trading results or investments.
                        Please ensure that you fully understand the risks involved.</p>

                    <p><a>© 2023, musafx</a><a>All rights reserved</a></p>
                </div>
            </div>



        </div>
    )
}

export default Footer