import React from 'react'
import "../styles/BrokerCard.css"

export default function BrokerCard(props) {
  return (
    <div className='broker-card'>
        <img src={props.image} alt='broker-image'/>
        <div className='broker-card-details'>
            <h3>OPEN ACCOUNT</h3>
            <button  className='siteButton'><a href={props.link}>click here</a></button>
        </div>
    </div>
  )
}
