import React,{useEffect, useState} from 'react'
import dataFetch from './modules/dataFetch'
import "../styles/AllUserTransactions.css"
import { baseURL } from '../utils';
function AllUserTransactions() {
    let [allTransactions, setAllTransactions] = useState();
    let [showTable, setShowTable] = useState(false)
    useEffect(() => {
        const fData = new FormData();
        fData.append('loadTransactions', "yes");
       
       const url = baseURL;
        dataFetch(url, fData)
            .then(data => {
                setAllTransactions(JSON.parse(data))
            })
            .catch(error => {
                console.error(error);
            });
        console.log(allTransactions)

    }, [])

    function confirmTransaction(task, index, transactionDate, paymentfor, amount){
        const fData = new FormData();
        fData.append(task, "yes");
        fData.append("email", index);
        fData.append("transactionDate", transactionDate);
        fData.append("paymentfor", paymentfor);
        fData.append("amount", amount);
  
       const url = baseURL;
        dataFetch(url, fData)
            .then(data => {
                console.log("Data is:" + data)
            })
            .catch(error => {
                console.error(error);
            });
    }
    return (
        <div>
            <button onClick={() => {
                setShowTable(!showTable);
                console.log(allTransactions)
          
            }}>{showTable ? "Hide table" : "Show Table"}</button>

            {showTable ? (
                <>

                    <table>
                        <thead>
                            <tr>

                                <th>Client</th>
                                <th>Payment For</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Payment Method</th>
                                <th>Screen Shot</th>
                                <th>Confirmed</th>

                               

                            </tr>
                        </thead>
                        <tbody>
                            {allTransactions.map(transaction => (
                                
                                <tr key={transaction.email}>
                                    <td>{transaction.email}</td>
                                    <td>{transaction.paymentfor}</td>
                                    <td>${transaction.amount}</td>
                                    <td>{transaction.transactionDate}</td>
                                    <td>{transaction.paymentMethod}</td>
                                    <td>{transaction.screenshot}</td>
                                    <td> <input className='action-display-button' type='submit' onClick={(e)=>{
                                                        // if (transaction.confirmed=="FALSE"){
                                                        //     confirmTransaction("confirmTransaction", transaction.email,transaction.transactionDate, transaction.paymentfor, transaction.amount);
                                                        //     e.target.value="TRUE"
                                                        // }
                                                        
                                                        confirmTransaction("confirmTransaction", transaction.email,transaction.transactionDate, transaction.paymentfor, transaction.amount);
                                                        //console.log("Hello")
                                                }} value={transaction.confirmed}/></td>
                                   
                                   
                                   
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </>

            ) : ("Not showing table")}
        </div>
    )
}

export default AllUserTransactions