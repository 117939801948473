import React from 'react'
import "../styles/OptionCard.css"

export default function OptionCard(props) {
  return (
    <div className='optionCard'>
      <div className='icon-image' ><img src={props.icon} alt="" /></div>
      <p style={{ color: `${props.color}` }}>{props.text}</p>
    </div>
  );
}
