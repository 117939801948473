import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  generalCart: [],
}

export const generalCartSlice = createSlice({
  name: 'generalCart',
  initialState,
  reducers: {
   
    addproduct: (state, action) => {
      state.generalCart.push(action.payload)
      
    },
    removeproduct: (state, action) => {
      state.generalCart.push(action.payload) 
    },
    removeAllProduct: (state, action) => {
      state.generalCart=[]
    },
  },
})

// Action creators are generated for each case reducer function
export const { addproduct, removeproduct, removeAllProduct } = generalCartSlice.actions

export default generalCartSlice.reducer